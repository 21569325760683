import React, { useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import { Range } from 'react-range';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { Input } from 'reactstrap';
import Classnames from 'classnames';
import { getForm, submitForm } from '../../services/CommonForm';
import { AppAlert } from "../../utils/Notification"
import style from './style.module.scss';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import { Checkbox, FormGroup } from '@material-ui/core';

const DEFAULT_CHOICE = 'DEFAULT_CHOICE'

const fieldTypeDefaults = {
  descriptive: DEFAULT_CHOICE,
  notes: '',
  text: '',
  slider: 0,
  dropdown: DEFAULT_CHOICE,
  yesno: DEFAULT_CHOICE,
  radio: DEFAULT_CHOICE,
  checkbox: [],
}

const DATE_VALIDATIONS = ['date_dmy', 'date_ymd']

const TEXT_VALIDATIONS = {
  'number': /(^[0-9]*$)|(^[0-9]+.[0-9]*$)/,
  'integer': /^[0-9]*$/
}

const TIME_VALIDATIONS = ['time']

const DelectableForms = ({ match, location }) => {

  const [cookies, setCookies] = useState('')
  const [result, setResult] = useState(undefined)
  const [preparedBody, setPreparedBody] = useState(undefined)
  const [fieldErrors, setFieldErrors] = useState(undefined)
  const [fieldValues, setFieldValues] = useState(undefined)
  const isLoading = useMemo(() => !result, [result])
  const queries = useMemo(() => new URLSearchParams(location.search), [location.search])

  useEffect(() => {
    const authorizationHeader = Cookies.getJSON()['Authorization']
    const getFormConfig = {
      appname: match.params.appname, formName: match.params.form, header: {
        Authorization: queries.get('auth')
      }
    }
    // if (!!authorizationHeader) {
    //   getFormConfig['header'] = { "Authorization": authorizationHeader }
    //   setCookies(authorizationHeader)
    // }

    if (match.params.appname === 'dtrg' && !queries.has('event_name')) {
      AppAlert(500, "Partial data provided")
      return
    }

    getForm(getFormConfig)
      .then((response) => {
        onParseForm(response.data)
        // setResult(response.data)
      })
      .catch(error => {
        if (!error.response) {
          // AppAlert(500, "cannot reach server")
          AppAlert(500, "cannot reach server")
        }
        else {
          // AppAlert(error.response.status, error.response.data.msg)
          setResult(error.response.data)
          AppAlert(500, error.response.data.detail)
        }
      })
      .finally(() => {
        // setLoading(false)
      })
  }, [match.params.form])

  useEffect(() => {
    if (!!preparedBody) {
      submitForm({
        appname: match.params.appname,
        body: preparedBody,
        header: {
          Authorization: queries.get('auth')
          // Authorization: Cookies.getJSON()['Authorization']
        }
      })
        .then((response) => {
          onFormSuccess()
        })
        .catch(error => {
          onFormFailure()
          // if (!error.response) {
          //   // AppAlert(500, "cannot reach server")
          //   AppAlert(500, "cannot reach server")
          // }
          // else {
          //   // AppAlert(error.response.status, error.response.data.msg)
          //   setResult(error.response.data)
          //   AppAlert(500, error.response.data.detail)
          // }
        })
        .finally(() => {
          // setLoading(false)
        })
    }
  }, [preparedBody])

  const onCheckboxChange = (fieldName, choiceValue, e, value) => {
    // const value = e.target.value
    // console.log(fieldName, choiceValue, e, value, typeof choiceValue);
    if (!!value) {
      const computedValue = [...fieldValues[fieldName], choiceValue]
      setFieldValues({ ...fieldValues, [fieldName]: computedValue })
      setFieldErrors({ ...fieldErrors, [fieldName]: false })
    }
    else {
      const computedValue = fieldValues[fieldName].filter((el) => el !== choiceValue)
      setFieldValues({ ...fieldValues, [fieldName]: computedValue })
    }
    // if (value !== DEFAULT_CHOICE) {
    //   setFieldValues({ ...fieldValues, [fieldName]: value })
    // }
  }

  const onFieldChange = (fieldName, e) => {
    const value = e.target.value
    if (value !== DEFAULT_CHOICE) {
      setFieldErrors({ ...fieldErrors, [fieldName]: false })
      setFieldValues({ ...fieldValues, [fieldName]: value })
    }
  }

  const onTextFieldChange = (fieldName, element, e) => {
    const value = e.target.value
    const validation = element.validation && element.validation.type ? element.validation.type : false
    console.log(validation, !Object.keys(TEXT_VALIDATIONS).includes(validation), value.match(TEXT_VALIDATIONS[validation]), TEXT_VALIDATIONS[validation]);
    if (!validation || !Object.keys(TEXT_VALIDATIONS).includes(validation) || value.match(TEXT_VALIDATIONS[validation])) {
      setFieldErrors({ ...fieldErrors, [fieldName]: false })
      setFieldValues({ ...fieldValues, [fieldName]: value })
    }
  }

  const onDateFieldChange = (fieldName, e) => {
    console.log(fieldName, e.format('YYYY-MM-DD'), e.format('HH:mm'));
    setFieldErrors({ ...fieldErrors, [fieldName]: false })
    setFieldValues({ ...fieldValues, [fieldName]: e })
  }

  const onSliderChange = (fieldName, e) => {

    const value = e[0]
    setFieldErrors({ ...fieldErrors, [fieldName]: false })
    setFieldValues({ ...fieldValues, [fieldName]: value })
  }

  const isDefaultValue = (targetField) => {
    if (Array.isArray(fieldTypeDefaults[targetField.name])) {
      return fieldValues[targetField.name].length > 0
    }
    else {
      return fieldValues[targetField.name] === fieldTypeDefaults[targetField.type]
    }
  }

  const validateFields = () => {
    let keysToSend = []
    const keys = Object.keys(fieldValues)
    const errors = {}
    let hasError = false
    keys.forEach(elementKey => {
      const target = result.find((el) => el.name === elementKey)
      if ((target.isRequired || (!target.isRequired && !isDefaultValue(target))) && branchingLogicValidator(target.branchingLogic)) {
        let isDate = false;
        if (target.type === 'text' && target.validation && target.validation.type && (DATE_VALIDATIONS.includes(target.validation.type) || TIME_VALIDATIONS.includes(target.validation.type))) {
          if (DATE_VALIDATIONS.includes(target.validation.type)) {
            isDate = "YYYY-MM-DD"
          }
          else if (TIME_VALIDATIONS.includes(target.validation.type)) {
            isDate = "HH:mm"
          }
        }
        keysToSend.push({ key: elementKey, isDate: isDate })
        const currnetElement = result.find(element => element.name === elementKey);
        if ((fieldValues[elementKey] === '') || (fieldValues[elementKey] === DEFAULT_CHOICE) ||
          (currnetElement.type === 'text' && (DATE_VALIDATIONS.includes(currnetElement.validation.type) || TIME_VALIDATIONS.includes(currnetElement.validation.type)) && fieldValues[elementKey] === null) ||
          (Array.isArray(fieldValues[elementKey]) && fieldValues[elementKey].length === 0)
        ) {
          errors[elementKey] = true
          hasError = true
        }
        else {
          errors[elementKey] = false
        }

      }
    })
    setFieldErrors({ ...errors })
    return hasError ? false : keysToSend
  }

  const answerGenerator = (key, isDate) => {
    if (Array.isArray(fieldValues[key])) {
      return fieldValues[key]
    }
    else {
      return !isDate ? [fieldValues[key]] : [fieldValues[key].format(isDate)]
    }
  }

  const onSubmitSurvey = () => {
    const validationResult = validateFields()
    if (!validationResult) {
      AppAlert(500, 'please fill all required fields')
    }
    else {
      const body = match.params.appname === 'dtrg' ?
        validationResult.map(({ key, isDate }) => ({
          form_name: match.params.form,
          field_name: key,
          answers: answerGenerator(key, isDate),
          timeline: +match.params.timeline,
          event_name: queries.get('event_name')
        })) :
        validationResult.map(({ key, isDate }) => ({
          form_name: match.params.form,
          field_name: key,
          answers: answerGenerator(key, isDate),
          timeline: +match.params.timeline
        }))
      setPreparedBody(body)
    }
  }

  const onParseForm = (formJson) => {
    let fields = []
    let errors = {}
    let values = {}
    formJson.forEach((element) => {
      if (element.field_type !== 'descriptive') {
        errors[element.field_name] = false
        switch (element.field_type) {
          case 'slider':
            values[element.field_name] = 0
            break;
          case 'checkbox':
            values[element.field_name] = []
            break;
          case 'text':
          case 'notes':
            values[element.field_name] = ''
            break;
          default:
            values[element.field_name] = DEFAULT_CHOICE
        }
        // if (element.field_type === 'text' && element.text_validation && element.text_validation.type && element.text_validation.type === 'date_dmy'){
        //   values[element.field_name] = null
        // }
      }
      fields.push({
        name: element.field_name,
        type: element.field_type,
        isRequired: element.required_field === true,
        label: element.field_label,
        sectionHeader: element.section_header ? element.section_header.replaceAll("\n", '<br/>')  : "",
        choices: element.field_type === 'yesno' ? [
          { option_id: "0", option_title: "No" },
          { option_id: "1", option_title: "Yes" }
        ] : element.choices,
        branchingLogic: element.branching_logic === null ? {} : element.branching_logic,
        validation: ['text', 'notes'].includes(element.field_type) ? element.text_validation : undefined
      })
    });
    setFieldValues(values)
    setFieldErrors(errors)
    setResult(fields)
  }

  const branchingLogicValidator = (logic, name = "dom") => {
    const keys = Object.keys(logic)
    if (keys.length === 0) {
      return true
    }
    for (let key of keys) {
      if (String(fieldValues[key]) !== logic[key]) {
        return false
      }
    }
    return true
  }

  const renderFormField = (element) => {

    switch (element.type) {
      case 'descriptive':
        return (<div key={element.field_name} dangerouslySetInnerHTML={{ __html: element.label }} />)
      case 'notes':
      case 'text':
        if (DATE_VALIDATIONS.includes(element.validation.type)) {
          return (
            <div key={element.field_name} className="form-group">
              <label htmlFor={element.field_name} className={Classnames({ [style["label-error"]]: fieldErrors[element.name] })}>{element.label}</label>
              <DatePicker error={fieldErrors[element.name]} value={fieldValues[element.name] === "" ? null : fieldValues[element.name]} disabled={!!preparedBody} id={element.field_name} allowKeyboardControl={false} emptyLabel='YYYY-MM-DD' format='YYYY-MM-DD' onChange={onDateFieldChange.bind(this, element.name)} autoOk={true} className={Classnames("form-control")} />
            </div>
          )
        }
        else if (TIME_VALIDATIONS.includes(element.validation.type)) {
          return (
            <div key={element.field_name} className="form-group">
              <label htmlFor={element.field_name} className={Classnames({ [style["label-error"]]: fieldErrors[element.name] })}>{element.label}</label>
              <TimePicker error={fieldErrors[element.name]} value={fieldValues[element.name] === "" ? null : fieldValues[element.name]} disabled={!!preparedBody} id={element.field_name} allowKeyboardControl={false} emptyLabel='HH:mm' format='HH:mm' onChange={onDateFieldChange.bind(this, element.name)} autoOk={true} className={Classnames("form-control")} />
            </div>
          )
        }
        return (
          <div key={element.field_name} class="form-group">
            <label htmlFor={element.field_name} className={Classnames({ [style["label-error"]]: fieldErrors[element.name] })}>{element.label}</label>
            <textarea
              className={Classnames("form-control", { [style["label-error"]]: fieldErrors[element.name] })}
              disabled={!!preparedBody}
              value={fieldValues[element.name]}
              onChange={onTextFieldChange.bind(this, element.name, element)}
              id={element.field_name}
              rows={element.type === "text" ? 1 : 3}
            />
          </div>
        )
      case 'slider':
        return (
          <div key={element.field_name} className="form-group pb-3">
            <div className="col-12 px-0 d-flex align-items-end justify-content-between" htmlFor={element.field_name}>
              {
                element.choices.map((el, key) => <label key={key} className={Classnames({ [style["label-error"]]: fieldErrors[element.name] }, 'col-3 px-0 mx-0')}>{el.option_id}</label>)
              }
            </div>
            <Range
              id={element.field_name}
              step={1}
              min={0}
              max={100}
              values={[fieldValues[element.name]]}
              onChange={onSliderChange.bind(this, element.name)}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '6px',
                    width: '100%',
                    backgroundColor: '#ccc'
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '20px',
                    width: '20px',
                    backgroundColor: '#5452e0',
                    borderRadius: '4px'
                  }}
                >
                  <span style={{ position: 'absolute', top: '20px', left: '-5px', width: '30px', textAlign: 'center', color: '#000' }}>{fieldValues[element.name]}</span>
                </div>
              )}
            />
          </div>
        )
      case 'dropdown':
        return (
          <div key={element.field_name} className="form-group">
            <label htmlFor={element.field_name}>{element.label}</label>
            <Input type="select" className={Classnames("form-control", { [style["input-error"]]: fieldErrors[element.name] })} disabled={!!preparedBody} id={element.field_name} value={fieldValues[element.name]} onChange={onFieldChange.bind(this, element.name)}>
              <option value={DEFAULT_CHOICE}>choose an option</option>
              {element.choices.map((choice, idx) => (
                <option key={idx} value={choice.option_id.trim()}>{choice.option_title}</option>
              ))}
            </Input>
          </div>
        )
      case 'checkbox':
        return (
          <FormControl key={element.field_name} className={Classnames(!!fieldErrors[element.name] ? style['question_error'] : undefined, style['fieldset'])} component="fieldset">
            <label component="legend">{element.label}</label>
            <FormGroup>
              {element.choices.map((choice, idx) => (
                // <FormControlLabel disabled={!!preparedBody} key={idx} value={choice.option_id} control={<Radio />} label={choice.option_title} />
                <FormControlLabel
                  key={idx}
                  disabled={!!preparedBody}
                  label={choice.option_title}
                  control={<Checkbox checked={Array.isArray(fieldValues[element.name]) && fieldValues[element.name].includes(choice.option_id)} onChange={onCheckboxChange.bind(this, element.name, choice.option_id)} />}
                />
              ))}

            </FormGroup>
          </FormControl>
        )
      case 'yesno':
      case 'radio':
        return (
          <FormControl key={element.field_name} className={Classnames(!!fieldErrors[element.name] ? style['question_error'] : undefined, style['fieldset'])} component="fieldset">
            <label >{element.label}</label>
            <RadioGroup aria-label={element.name} name={element.name} value={fieldValues[element.name]} onChange={onFieldChange.bind(this, element.name)}>
              {element.choices.map((choice, idx) => (
                <FormControlLabel disabled={!!preparedBody} key={idx} value={choice.option_id} control={<Radio />} label={choice.option_title} />
              ))}
            </RadioGroup>
          </FormControl>
        )
      default:
        return undefined
    }
  }

  const renderForm = () => {
    return result.map((element) => {
      return branchingLogicValidator(element.branchingLogic, element.name) ?
      <>
      {element.sectionHeader ? 
      <div className={style['sectionHead']} key={`${element.field_name}_head`} dangerouslySetInnerHTML={{ __html: element.sectionHeader }} /> : undefined}
        {renderFormField(element)}
      </> :
        undefined
    }
    )
  }

  const renderError = () => {
    return (
      <span>something is wrong</span>
    )
  }

  const debugMode = () => {
    return (
      <>
        <button onClick={onFormSuccess}>on simulate success</button>
        <button onClick={onFormFailure}>on simulate failure</button>
        <div>
          <span>{`os: ${queries.get('os') || 'none'}`}</span>
        </div>
        <div>
          <span>{`theme: ${queries.get('theme') || 'none'}`}</span>
        </div>
        <div>
          <span>{`auth: ${queries.get('auth') || 'none'}`}</span>
        </div>
        <div>
          <span>{`Authorization-token: ${cookies || 'none'}`}</span>
        </div>
      </>
    )
  }

  const prodMode = () => {
    return (
      <>
        {
          isLoading ? (
            <div className={style['loading-wrapper']}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <div>
                {
                  Array.isArray(result) ? renderForm() : renderError()
                }
              </div>
              <div className={style['submit_wrapper']}>
                <Button className={style['submit_btn']} onClick={onSubmitSurvey} disabled={!!preparedBody} variant="contained" color="secondary">{!!preparedBody ? 'Submitting...' : 'Submit Survey'}</Button>
              </div>
            </>
          )
        }
      </>
    )
  }

  const onFormSuccess = () => {
    setPreparedBody(undefined)
    const eventName = ['delectable', 'miro'].includes(match.params.appname) ? 'delectable' : match.params.appname
    window.open(`${eventName}://success?queryOne=valueOne&queryTwo=valueTwo`, '_self')
  }

  const onFormFailure = () => {
    setPreparedBody(undefined)
    const eventName = ['delectable', 'miro'].includes(match.params.appname) ? 'delectable' : match.params.appname
    window.open(`${eventName}://failure?query5=valueFive&queryThree=value3`, '_self')
  }

  return (
    <div className={Classnames('container', style["root"])}>
      {
        !!queries.has('debug') ? debugMode() : prodMode()
      }
    </div>
  )
}

export default DelectableForms