import Axios from 'axios';

const delectableClient = Axios.create({
    baseURL: `${process.env.REACT_APP_DELECTABLE_PROTOCOL}://${process.env.REACT_APP_DELECTABLE_BASE_ADDRESS}`,
    // baseURL: `http://localhost:8000/api/v1`,
    responseType: "json"
    // ,
    // withCredentials: true
});

const dtrgClient = Axios.create({
    baseURL: `${process.env.REACT_APP_DTRG_PROTOCOL}://${process.env.REACT_APP_DTRG_BASE_ADDRESS}`,
    // baseURL: `http://localhost:8000/api/v1`,
    responseType: "json"
    // ,
    // withCredentials: true
});

const miroClient = Axios.create({
    baseURL: `${process.env.REACT_APP_MIRO_PROTOCOL}://${process.env.REACT_APP_MIRO_BASE_ADDRESS}`,
    // baseURL: `http://localhost:8000/api/v1`,
    responseType: "json"
    // ,
    // withCredentials: true
});

export default {
    dtrg: dtrgClient,
    miro: miroClient,
    delectable: delectableClient
}