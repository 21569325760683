import clients from './SharedConfig';

export const getForm = ({header={}, formName="", appname=""} = {}) => {
    const config = {
        method: 'GET',
        url: `/survey/form/${formName}/`,
        headers:{
            ...header
        }
    };
    return clients[appname].request(config )
}

export const submitForm = ({header={}, body={}, appname=""} = {}) => {
    const config = {
        method: 'POST',
        url: '/survey/submit/',
        data: body,
        headers:{
            ...header
        }
    };
    return clients[appname].request(config )
};