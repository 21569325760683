import React from 'react';

const NotFound = () => {

    return (
        <div>
            404 - not found
        </div>
    )
}

export default NotFound;