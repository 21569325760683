import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import DelectableForms from '../pages/DelectableForms';
import NotFound from '../pages/NotFound';

const AppRouter = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path='/:appname/:form/:timeline' component={DelectableForms} />
                <Route component={NotFound} />
            </Switch>
        </BrowserRouter>
    )
}

export default AppRouter;